<template>
  <div>
    <div class="d-flex align-items-center justify-content-center" style="min-height: 400px;">
      <img :src="getImage(selected)" alt="product is not found" style="max-height: 450px; max-width: 100%" />
    </div>
    <div class="bg-light border d-flex py-2 flex-wrap">
      <div class="mx-2 d-flex align-items-center justify-content-center hover" :class="{ selected: selected == index }"
        style="height: 70px; cursor: pointer" v-for="(image, index) in images" :key="index" @click="selectImage(index)">
        <img :src="getImage(index)" style="width: 100%; height: 100%" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiImageViewer",
  props: {
    images: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      selected: 0,
    };
  },
  methods: {
    getImage(index) {
      if (this.images == null) {
        return require("@/assets/images/product.png");
      }
      if (this.images.length != 0 && this.images[index] != "img") {
        // Check if image is active
        // TODO: check image active
        // const img = fetch(this.product.img[0]);
        // console.log(img);
        return this.images[index];
      } else {
        return require("@/assets/images/product.png");
      }
    },
    selectImage(index) {
      this.selected = index;
    },
  },
};
</script>

<style scoped>
.selected {
  background-color: #ccc;
  border: 1px solid #777;
}

.hover:hover {
  background-color: #ccc;
}
</style>
