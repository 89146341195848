<template>
	<div class="theme-dashboard-view" style="padding-bottom: 0">
		<!-- Loading Indicator -->
		<div class="py-5 d-flex justify-content-center align-items-center" v-if="!display">
			<LoadingIndicator :active="loading" />
			<div v-show="load_error">
				<div class="justify-content-center text-center text-danger my-4">
					<h4>{{ load_error }}</h4>
				</div>
			</div>
		</div>
		<!-- {{ product }} -->
		<!-- Product General Information  -->
		<div class="row m-0" v-show="!loading && display">
			<!-- Product Image Left Side -->
			<div class="col-lg-6 d-flex flex-column">
				<MultiImageViewer :images="this.product.img" />
				<!-- Load image -->
				<!-- <img
					:src="getImage()"
					alt="product"
					style="width: 100%; max-height: 100%"
				/> -->
			</div>
			<!-- Product Information Right Side  -->
			<div class="col-lg-6 col-md-12">
				<!-- Basic info -->
				<div class="my-3 text-center d-flex align-items-center justify-content-center"
					style="min-height: 200px">
					<div>
						<h1 class="m-0">{{ product.name }}</h1>
						<p class="m-0">
							{{ category.name }}
						</p>
					</div>
				</div>

				<!-- Price  -->
				<div class="d-flex border rounded my-3">
					<div class="col-3 col-md-3 col-lg-4 border py-4 px-1 text-center">
						<strong>{{ $t("inventory_product_posname") }}</strong>
					</div>
					<div class="col-9 col-md-8 col-lg-9 d-flex align-items-center px-3 flex-wrap">
						{{ product.pos_name }}
					</div>
				</div>
				<!-- Price  -->
				<div class="d-flex border rounded my-3">
					<div class="col-3 col-md-3 col-lg-4 border py-4 px-1 text-center">
						<strong>{{ $t("inventory_product_price") }}</strong>
					</div>
					<div class="col-9 col-md-8 col-lg-9 d-flex align-items-center px-3 flex-wrap">
						{{ product.price }} ETB
					</div>
				</div>

				<!-- Quantity -->
				<div class="d-flex border rounded my-3">
					<div class="col-3 col-md-3 col-lg-4 border py-4 px-1 text-center">
						<strong>{{ $t("inventory_product_description") }}</strong>
					</div>
					<div class="col-9 col-md-9 col-lg-8 d-flex align-items-center px-3 flex-wrap">
						{{ product.description }}
					</div>
				</div>

				<!-- Tags -->
				<div class="d-flex border rounded my-3">
					<div class="col-3 col-md-3 col-lg-4 border py-4 px-1 text-center">
						<strong>{{ $t("inventory_product_tags") }}</strong>
					</div>
					<div class="col-9 col-md-9 col-lg-8 d-flex align-items-center px-3 py-2 flex-wrap">
						<div v-for="(tag, index) in product.tags" :key="index"
							class="mx-1 my-1 px-4 py-2 rounded-pill border">
							{{ tag }}
						</div>
					</div>
				</div>

				<!-- Rating -->
				<!-- <div class="d-flex border rounded mb-3">
          <div class="col-3 col-md-3 col-lg-4 border py-4 px-1 text-center">
            <strong>{{ $t("inventory_product_average_rating") }}</strong>
          </div>
          <div
            class="col-9 col-md-9 col-lg-8 d-flex align-items-center px-3 flex-wrap"
          >
            {{ product.rating }}
          </div>
        </div> -->

				<!-- <div>
					<div>
						<strong>Date Created:</strong>
						{{ DateFormat(product.createdAt) }}
					</div>

					<div>
						<strong>Date Updated:</strong>
						{{ DateFormat(product.updatedAt) }}
					</div>
				</div> -->
			</div>
		</div>

		<!-- Quantity Information -->
		<div class="p-5">
			<div class="">
				<h3 class="m-0">{{ $t("inventory_product_quantity_information") }}</h3>
			</div>
			<div class="d-flex flex-wrap">
				<div class="p-4 col-lg-6 col-12">
					<!-- Minimum Order Quantity -->
					<div class="d-flex border rounded my-4">
						<div class="col-3 col-md-3 col-lg-4 border py-4 px-1 text-center">
							<strong>{{ $t("inventory_product_minimum_order_quantity") }}</strong>
						</div>
						<div class="col-9 col-md-9 col-lg-8 d-flex align-items-center px-3 flex-wrap">
							{{ product.minimum_order_quantity }}
							{{ getUnit(product.unit) }}
						</div>
					</div>

					<!-- Maximum Order Quantity -->
					<div class="d-flex border rounded my-4">
						<div class="col-3 col-md-3 col-lg-4 border py-4 px-1 text-center">
							<strong>{{ $t("inventory_product_maximum_order_quantity") }}</strong>
						</div>
						<div class="col-9 col-md-9 col-lg-8 d-flex align-items-center px-3 flex-wrap">
							{{ product.maximum_order_quantity }}
							{{ getUnit(product.unit) }}
						</div>
					</div>

					<!-- Quantity Units -->
					<!-- <div class="d-flex border rounded my-4">
						<div
							class="
								col-3 col-md-3 col-lg-4
								border
								py-4
								px-1
								text-center
							"
						>
							<strong>Quantity Units</strong>
						</div>
						<div
							class="
								col-9 col-md-9 col-lg-8
								d-flex
								align-items-center
								px-3
								flex-wrap
							"
						>
							{{ product.unit }}
						</div>
					</div> -->
				</div>
				<div class="p-4 col-lg-6 col-12" style="min-height: 250px">
					<!-- Almondi Quantity -->
					<div class="d-flex border rounded my-4">
						<div class="col-3 col-md-3 col-lg-4 border py-4 px-1 text-center">
							<strong>{{ $t("inventory_product_amoudi_quantity") }}</strong>
						</div>
						<div class="col-9 col-md-9 col-lg-8 d-flex align-items-center px-3 flex-wrap">
							{{ product.amoudi_quantity }}
							{{ getUnit(product.unit) }}
						</div>
					</div>

					<!-- Nani Quantity -->
					<div class="d-flex border rounded my-4">
						<div class="col-3 col-md-3 col-lg-4 border py-4 px-1 text-center">
							<strong>{{ $t("inventory_product_nani_quantity") }}</strong>
						</div>
						<div class="col-9 col-md-9 col-lg-8 d-flex align-items-center px-3 flex-wrap">
							{{ product.nani_quantity }}
							{{ getUnit(product.unit) }}
						</div>
					</div>
					<!-- Sara Quantity -->
					<!--          <div class="d-flex border rounded my-4">-->
					<!--            <div class="col-3 col-md-3 col-lg-4 border py-4 px-1 text-center">-->
					<!--              <strong>Sara Quantity</strong>-->
					<!--            </div>-->
					<!--            <div-->
					<!--              class="col-9 col-md-9 col-lg-8 d-flex align-items-center px-3 flex-wrap"-->
					<!--            >-->
					<!--              {{ product.sara_quantity }}-->
					<!--              {{ getUnit(product.unit) }}-->
					<!--            </div>-->
					<!--          </div>-->
				</div>
			</div>
		</div>

		<!-- TODO: Statistics, Information, Feedback -->

		<!-- Bottom Bar Buttons -->
		<div class="d-flex justify-content-evenly theme-background-secondary p-3 border rounded"
			style="position: sticky; bottom: 0" v-if="!loading && display">
			<button class="btn bg-primary text-light" @click="editProduct">
				<i class="fas fa-edit"></i>
				{{ $t("inventory_product_edit_button") }}
			</button>
			<button class="btn bg-danger text-light" @click="deleteProduct">
				<i class="fa fa-trash" aria-hidden="true"></i>
				{{ $t("inventory_product_delete_button") }}
			</button>
		</div>
	</div>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import MultiImageViewer from "@/components/Multi-Image-Viewer.vue";

// Services
import ProductService from "@/services/products.js";
import CategoryService from "@/services/category.js";

export default {
	name: "Inventory Product",
	components: {
		LoadingIndicator,
		MultiImageViewer,
	},
	data() {
		return {
			product: {},
			category: {},
			display: false,
			loading: false,
			load_error: "",
		};
	},
	methods: {
		// TODO: localize
		getUnit(index) {
			switch (index) {
				case 0:
					return "units";
				case 1:
					return "kilos";
				case 2:
					return "liters";
			}
		},
		DateFormat(datestring) {
			const date = new Date(Date.parse(datestring));
			return date;
		},
		deleteProduct() {
			ProductService.deleteProduct(this.product.id).then(
				() => {
					this.$router.push("/search");
				},
				(error) => {
					this.load_error =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
				}
			);
		},
		editProduct() {
			this.$router.push("/inventory/update/" + this.product.id);
		},
		// getImage() {
		// 	if (this.product.img && this.product.img[0] != "img") {
		// 		// Check if image is active
		// 		// const img = fetch(this.product.img[0]);
		// 		// console.log(img);
		// 		return this.product.img[0];
		// 	} else {
		// 		return require("@/assets/images/product.png");
		// 	}
		// },
	},
	mounted() {
		// Set Loading indicator to running
		// Empty error text
		this.loading = true;
		this.load_error = "";

		ProductService.getSingleProduct(this.$route.params.id).then(
			(response) => {
				this.loading = false;
				this.display = true;
				this.product = response.data.data;
				console.log("PRODUCT:", response);
				CategoryService.getSingleCategory(this.product.category_id).then(
					(response) => {
						this.category = response.data.data.category;
					},
					(error) => {
						console.log(error);
					}
				);
			},
			(error) => {
				this.loading = false;
				console.log("Error:", error);
				this.load_error =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();
			}
		);
	},
};
</script>
